// extracted by mini-css-extract-plugin
export var about = "about-module--about--qxenx";
export var aboutContainer = "about-module--aboutContainer--f6s0z";
export var aboutHeader = "about-module--aboutHeader--b+hwm";
export var arrowBounceDown = "about-module--arrowBounceDown--ugL+Q";
export var arrowBounceRight = "about-module--arrowBounceRight--spBjW";
export var letter = "about-module--letter--PhrUe";
export var mainContent = "about-module--mainContent--68WUO";
export var mainImage = "about-module--mainImage--o3hGo";
export var mainImageContainer = "about-module--mainImageContainer--C-Ivv";
export var mainText = "about-module--mainText--sW-qQ";
export var nextPage = "about-module--nextPage--k9qHQ";
export var projectsBtn = "about-module--projectsBtn--ULsun";
export var projectsBtnIcon = "about-module--projectsBtnIcon--Uqb1a";
export var revealImage = "about-module--revealImage--Oj3xj";
export var waveIcon = "about-module--waveIcon--0j6e3";