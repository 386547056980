import gsap  from "gsap"
import { TextPlugin } from "gsap/all"

gsap.registerPlugin(TextPlugin);

export class aboutTextAnim {
    constructor(props) {
        this.letterH = props.letterH;
        this.letterE = props.letterE;
        this.letterL1 = props.letterL1;
        this.letterL2 = props.letterL2;
        this.letterO = props.letterO;
        this.letterEX = props.letterEX;
        this.handWave = props.handWave;
        this.mainContent = props.mainContent;
        this.init();
    }

    animateOpeningText () {
        let handWavetl = gsap.timeline()

        gsap.to(this.letterH, { y: 0 })
        gsap.to(this.letterE, { y: 0, delay: 0.1 })
        gsap.to(this.letterL1, { y: 0, delay: 0.2})
        gsap.to(this.letterL2, { y: 0, delay: 0.3 })
        gsap.to(this.letterO, { y: 0, delay: 0.4 })
        gsap.to(this.letterEX, { y: 0, delay: 0.5 })

        handWavetl
            .to(this.handWave, { x: 50, delay: 1, opacity: 1, duration: 0.45, rotation: 25 })
            .to(this.handWave, { rotation: 0, duration: 0.25 })
            .to(this.mainContent, { opacity: 1, y: 0 })
    }

    init () {
        this.animateOpeningText()
    }
}