import React from 'react'
import * as aboutStyles from '../stylesheets/about.module.scss'
import { aboutTextAnim } from '../gsap/about'
import { graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Img from "gatsby-image"

export default function About({ data }) {

    let letterH = React.useRef(null)
    let letterE = React.useRef(null)
    let letterL1 = React.useRef(null)
    let letterL2 = React.useRef(null)
    let letterO = React.useRef(null)
    let letterEX = React.useRef(null)
    let handWave = React.useRef(null)
    let mainContent = React.useRef(null)

    let headerAnimation

    React.useEffect(() => {
        headerAnimation = new aboutTextAnim({
            letterH,
            letterE,
            letterL1,
            letterL2,
            letterO,
            letterEX,
            handWave,
            mainContent
        })
    }, [])

    return (
        <div className={aboutStyles.about}>
            <div className={aboutStyles.aboutContainer}>
                <h1 className={aboutStyles.aboutHeader}>
                    <span className={aboutStyles.letter} ref={(el) => (letterH = el)}>H</span>
                    <span className={aboutStyles.letter} ref={(el) => (letterE = el)}>e</span>
                    <span className={aboutStyles.letter} ref={(el) => (letterL1 = el)}>l</span>
                    <span className={aboutStyles.letter} ref={(el) => (letterL2 = el)}>l</span>
                    <span className={aboutStyles.letter} ref={(el) => (letterO = el)}>o</span>
                    <span className={aboutStyles.letter} ref={(el) => (letterEX = el)}>!</span>
                    <span ref={(el) => (handWave = el)}  className={aboutStyles.waveIcon}>
                        <Img fluid={data.handwave.childImageSharp.fluid} />
                    </span>
                </h1>
            </div>
            <div ref={el => (mainContent = el)}className={aboutStyles.mainContent}>
                <div className={aboutStyles.mainText}>
                        <p>
                            I'm based in Japan and currently working as a full stack developer in Tokyo.
                            I most enjoy working with Python and JavaScript to create interesting applications that can help others.
                        </p>
                        <p>
                            <br />
                            I've been living in Japan for 10 years and speak the language, so I can offer translation services as well.
                            I play guitar in my spare time and sometimes do live performances.
                        </p>
                        <p>
                            <br />
                            Thanks for taking a look!
                        </p>
                        <AniLink className={aboutStyles.projectsBtn} cover bg="#4d9699" direction="right" to="/projects">
                            <p>See Projects</p>
                            <FontAwesomeIcon className={aboutStyles.projectsBtnIcon} icon={faArrowCircleRight} />
                        </AniLink>
                </div>
                <div className={aboutStyles.mainImageContainer}>
                    <div className={aboutStyles.mainImage}>
                        <Img fluid={data.shiba.childImageSharp.fluid} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export const query = graphql`
query {
    handwave: file(relativePath: {eq: "wavehand.png"}) {
      id
      childImageSharp {
        fluid {
            ...GatsbyImageSharpFluid
        }
      }
    }
    shiba: file(relativePath: {eq: "intropic.jpg"}) {
        id
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid
          }
        }
      }
  }
`